import { useController } from 'react-hook-form';
import React, { type MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Control } from 'react-hook-form/dist/types/form';
import type { ICase, IProcedureSelectOption, ISelectOption } from 'app/mobxStore/types';
import { FORM_FIELDS_ENUM, type IFormData } from 'app/components/fields/types';
import clsx from 'clsx';
import ProcedureSuggestionButton from './ProcedureSuggestionButton';
import ProcedureSelectPanel from './ProcedureSelectPanel';
import Button from 'app/components/buttons/Button';
import PageIcon from 'app/assets/icons/Page_icon';
import SearchIcon from 'app/assets/icons/Search_icon';
import variables from 'app/utils/variables.module.scss';
import useProcedureSuggestions from 'app/components/caseViews/hooks/useProcedureSuggestions';
import AmplService from 'app/services/amplService/amplService';
import Grow from '@mui/material/Grow';
import AddTemplatePanel from 'app/components/addTemplate/AddTemplatePanel';

import { useRootStore } from 'app/mobxStore';
import ffService from '../../../services/ffService/ffService';

interface IProps {
  control: Control<IFormData>;
  options: IProcedureSelectOption[];
  isReadOnly?: boolean;
  procSuggestions: IProcedureSelectOption[];
  allValues?: IFormData;
  required: boolean;
  loaded: boolean;
  onChange: (option: IProcedureSelectOption | null) => void;
  isAddTemplate?: boolean;
  addNewTemplateName?: string;
}

const ProcedureSelect = (props: IProps): React.JSX.Element | null => {
  const {
    loaded,
    required,
    control,
    options,
    isReadOnly,
    procSuggestions,
    allValues,
    onChange,
    isAddTemplate,
    addNewTemplateName
  } = props;

  const addYourOwnTemplateEnabled = ffService.isAddYourOwnTemplateEnabled();

  const {
    field: { value }
  } = useController({
    rules: { required },
    name: FORM_FIELDS_ENUM.PROCEDURE,
    control
  });
  const isProcSelected = value !== undefined && value !== null;
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const { openedCase } = useRootStore().caseStore;
  const [renderedSuggestionIndex, setRenderedSuggestionIndex] = useState(0);
  const [isAddTemplateButtonIn, setIsAddTemplateButtonIn] = useState(false);
  const [isOpenBySelectOption, setIsOpenBySelectOption] = useState(false);
  const [selectedTemplateForAddNew, setSelectedTemplateForAddNew] = useState<ISelectOption | null>(
    null
  );

  const { procSuggestions: procSuggestionsFiltered } = useProcedureSuggestions({
    procedures: options,
    procedureTitle: allValues ? allValues[FORM_FIELDS_ENUM.PROCEDURE]?.label ?? '' : '',
    title: inputValue,
    description: allValues
      ? isAddTemplate && addNewTemplateName
        ? addNewTemplateName
        : allValues[FORM_FIELDS_ENUM.DESCRIPTION]
      : '',
    siteId: allValues ? allValues[FORM_FIELDS_ENUM.SERVICE]?.value ?? '' : '',
    attendingId: allValues ? allValues[FORM_FIELDS_ENUM.SURGEON]?.value ?? '' : ''
  });

  const [isClicked, setIsClicked] = useState(false);

  if (isReadOnly && !isProcSelected) {
    return null;
  }

  const handleClose = (): void => {
    setIsOpen(false);
    setIsInputFocused(false);
  };

  const sendEvent = (event: string, md = {}): void => {
    AmplService.sendCaseEvent(event, openedCase?.basicCase as ICase, {
      ...md,
      [AmplService.ATTRIBUTES.INPUT_VALUE]: inputValue
    });
  };

  const handleClickSuggestion = (suggestion: IProcedureSelectOption): void => {
    setIsClicked(true);
    if (isAddTemplate) {
      setSelectedTemplateForAddNew(suggestion);
    }
    onChange(suggestion);
    sendEvent(AmplService.EVENTS.CASE_PROCEDURE_SUGGESTION, {
      [AmplService.ATTRIBUTES.SELECTED_TEMPLATE]: suggestion.label
    });
  };

  const handleOnChange = (selectedValue: IProcedureSelectOption): void => {
    handleClose();
    if (selectedValue.value === 'add') {
      setIsOpenBySelectOption(true);
      return;
    }
    setIsClicked(true);
    if (isAddTemplate) {
      setSelectedTemplateForAddNew(selectedValue);
    }
    onChange(selectedValue);
    sendEvent(AmplService.EVENTS.SEARCH_TEMPLATE_CHOOSE, {
      [AmplService.ATTRIBUTES.SELECTED_TEMPLATE]: selectedValue.label,
      [AmplService.ATTRIBUTES.IS_FROM_RECOMMENDATIONS]:
        procSuggestionsFiltered.includes(selectedValue)
    });
  };

  const getButtonContent = (proc: ISelectOption | undefined): React.JSX.Element => {
    if (isAddTemplate) {
      return <div className="proc-name">{selectedTemplateForAddNew?.label}</div>;
    }
    if (proc === undefined) {
      return (
        <div className="select-field-content">
          <div className="placeholder">{t('selectFromAList')}</div>
        </div>
      );
    }
    return <div className="proc-name">{proc.label}</div>;
  };

  // show add template button if no suggestions
  useEffect(() => {
    if (loaded && procSuggestions.length === 0) {
      setIsAddTemplateButtonIn(true);
    }
  }, [loaded, procSuggestions]);

  useEffect(() => {
    if (!isOpen) {
      setInputValue('');
      setIsInputFocused(false);
    }
  }, [isOpen]);

  if (!isProcSelected && !selectedTemplateForAddNew && (!loaded || isClicked)) {
    return null;
  }

  const optionsWithAddTemplate = [...options, { label: t('addYourOwnTemplate'), value: 'add' }];
  const getOptions = (): ISelectOption[] => {
    if (!addYourOwnTemplateEnabled) {
      return options;
    }
    if (isAddTemplate) {
      return options;
    }
    return optionsWithAddTemplate;
  };

  return (
    <div className="procedure-select">
      {!isProcSelected && (
        <div className="title">
          {t(isAddTemplate ? 'selectExistingTemplate' : 'selectTemplate')}
        </div>
      )}
      {procSuggestions.length === 0 ||
      isProcSelected ||
      (isAddTemplate && selectedTemplateForAddNew) ? (
        <Button
          disabled={isReadOnly}
          classNames={clsx('select-field', { 'read-only': isReadOnly })}
          buttonContent={getButtonContent(value)}
          startIcon={<PageIcon />}
          onClick={async (e: MouseEvent) => {
            AmplService.sendCaseEvent(
              AmplService.EVENTS.CASE_SEARCH_TEMPLATE,
              openedCase?.basicCase as ICase
            );
            setIsOpen(true);
          }}
        />
      ) : (
        <div className="proc-suggestions">
          <div className="search-procedure-button-container">
            <Button
              classNames="search-procedure-button"
              buttonContent={
                <div className="content">
                  <p>{t('searchTemplates')}</p>
                  <div className="icon-container">
                    <SearchIcon stroke={variables.grey6} />
                  </div>
                </div>
              }
              onClick={async (e: MouseEvent) => {
                setIsOpen(true);
                sendEvent(AmplService.EVENTS.CASE_SEARCH_TEMPLATE);
              }}
            />
          </div>
          <div className="suggestions">
            <div className="suggestions-label">{t('topSuggestions')}</div>
            {procSuggestions.slice(0, 5).map((proc, index) => (
              <Grow
                key={proc.value}
                in={renderedSuggestionIndex >= index}
                timeout={800}
                style={{ transformOrigin: '0 0 0' }}
                onEnter={() => {
                  setTimeout(() => {
                    if (index + 1 === procSuggestions.length || index + 1 === 5) {
                      setIsAddTemplateButtonIn(true);
                    }
                    setRenderedSuggestionIndex(index + 1);
                  }, 100);
                }}
              >
                <div className="suggestion-button-container">
                  <ProcedureSuggestionButton
                    key={proc.value}
                    procedure={proc}
                    handleClickSuggestion={handleClickSuggestion}
                  />
                </div>
              </Grow>
            ))}
          </div>
        </div>
      )}
      {addYourOwnTemplateEnabled && !isAddTemplate && !isProcSelected && allValues && (
        <AddTemplatePanel
          control={control}
          options={options}
          allValues={allValues}
          isIn={isAddTemplateButtonIn}
          onChange={onChange}
          isOpenBySelectOption={isOpenBySelectOption}
          setIsOpenBySelectOption={setIsOpenBySelectOption}
        />
      )}
      <ProcedureSelectPanel
        control={control}
        required={required}
        handleClose={handleClose}
        handleOnChange={handleOnChange}
        options={getOptions()}
        inputValue={inputValue}
        setInputValue={setInputValue}
        procSuggestionsFiltered={procSuggestionsFiltered}
        isInputFocused={isInputFocused}
        setIsInputFocused={setIsInputFocused}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        sendEvent={sendEvent}
      />
    </div>
  );
};

export default ProcedureSelect;
