import { useEffect } from 'react';
import { useRootStore } from 'app/mobxStore';
import oneSignalService from 'app/services/oneSignalService';

const useIsOptIn = (): void => {
  const rootStore = useRootStore();
  const { userStore } = rootStore;
  const currentUserId = userStore.loggedInUser.data.id;

  useEffect(() => {
    const isOptIn = async (): Promise<void> => {
      const is = await oneSignalService.isOptedIn();

      if (is) {
        await oneSignalService.handleLogin(currentUserId, userStore.loggedInUser.data.email);
      }
    };

    void isOptIn();
  }, []);
};

export default useIsOptIn;
