import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'app/components/buttons/Button';
import oneSignalService from 'app/services/oneSignalService';
import { useRootStore } from '../../mobxStore';
import RemindIcon from 'app/assets/icons/Remind_icon';
import { observer } from 'mobx-react-lite';
import variables from 'app/utils/variables.module.scss';
import AmplService from '../../services/amplService/amplService';

const EnablePushButton = (): React.JSX.Element => {
  const { t } = useTranslation();
  const { userStore, isPushOptIn, pushSupported } = useRootStore();
  const currentUserId = userStore.loggedInUser.data.id;
  const [shouldOptIn, setShouldOptedIn] = useState<boolean>(false);

  useEffect(() => {
    setShouldOptedIn(pushSupported && !isPushOptIn);
  }, [pushSupported, isPushOptIn]);

  const enablePush = async (): Promise<void> => {
    void oneSignalService.showOneSignalPopup(
      currentUserId,
      userStore.loggedInUser.data.email,
      true
    );
    AmplService.sendEvent(AmplService.EVENTS.ACCOUNT_ENABLE_NOTIFICATIONS);
  };

  if (!shouldOptIn) {
    return <></>;
  }

  return (
    <Button
      classNames="btn action-button"
      onClick={enablePush}
      buttonContent={
        <div className="button-content">
          <div className="button-icon">
            <RemindIcon height={14} stroke={variables.black} />
          </div>
          {t('enablePush')}
        </div>
      }
    />
  );
};

export default observer(EnablePushButton);
