import React, { type ReactElement, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import oneSignalService from 'app/services/oneSignalService';
import AmplService from 'app/services/amplService/amplService';

import './EmptyList.scss';
import { useRootStore } from '../../../mobxStore';
import { CaseStatusEnum } from '../../../mobxStore/types';

const getContent = (
  selectedStatusFilter: CaseStatusEnum,
  hasUpcomingHuddles: boolean,
  enableNotifications: boolean
): { text: string; onClick: (() => Promise<void>) | undefined; buttonText?: string } => {
  const { caseStore, userStore } = useRootStore();
  const currentUserId = userStore.loggedInUser.data.id;

  const enablePushButton = {
    onClick: async (): Promise<void> => {
      void oneSignalService.showOneSignalPopup(
        currentUserId,
        userStore.loggedInUser.data.email,
        true
      );
      AmplService.sendEvent(AmplService.EVENTS.HOME_CASE_LIST_EMPTY_ENABLE_NOTIFICATIONS);
    },
    text: 'enablePush'
  };

  if (selectedStatusFilter === CaseStatusEnum.UPCOMING) {
    // UPCOMING
    if (!enableNotifications) {
      // notifications already enabled
      return { text: 'emptyStateLiteNonSurgeonNotificationsEnabledUpcoming', onClick: undefined };
    }

    // should enable notifications
    return {
      text: 'emptyStateLiteNonSurgeonNotificationsDisabledUpcoming',
      onClick: enablePushButton.onClick,
      buttonText: enablePushButton.text
    };
  }

  if (enableNotifications) {
    // CURRENT - notifications disabled
    return {
      text: 'emptyStateLiteNonSurgeonNotificationsDisabledCurrent',
      onClick: enablePushButton.onClick,
      buttonText: enablePushButton.text
    };
  }

  // CURRENT and has upcoming
  if (hasUpcomingHuddles) {
    return {
      text: 'emptyStateHuddlesUpcoming',
      onClick: async (): Promise<void> => {
        caseStore.setSelectedStatusFilter(CaseStatusEnum.UPCOMING);
      }
    };
  }

  // CURRENT - notifications enabled
  return { text: 'emptyStateLiteNonSurgeonNotificationsEnabledCurrent', onClick: undefined };
};

const useEmptyListLiteNonSurgeon = (): {
  text: ReactElement;
  onClick: (() => Promise<void>) | undefined;
  buttonText?: string;
} => {
  const { caseStore, metaDataStore, isPushOptIn, pushSupported } = useRootStore();
  const selectedStatusFilter = caseStore.selectedStatusFilter;
  const hasUpcomingHuddles = caseStore.hasCaseInStatus(CaseStatusEnum.UPCOMING);
  const surgeonName =
    metaDataStore.surgeonsOptions.length === 1
      ? metaDataStore.surgeonsOptions[0].label
      : 'the surgeon';
  const [shouldOptIn, setShouldOptedIn] = useState<boolean>(false);

  useEffect(() => {
    setShouldOptedIn(pushSupported && !isPushOptIn);
  }, [pushSupported, isPushOptIn]);

  const content = getContent(selectedStatusFilter, hasUpcomingHuddles, shouldOptIn);

  return {
    text: <Trans i18nKey={content.text} values={{ surgeon: surgeonName }} />,
    onClick: content.onClick,
    buttonText: content.buttonText
  };
};

export default useEmptyListLiteNonSurgeon;
