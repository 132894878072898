import React, { useCallback, useEffect, useRef, useState } from 'react';
import OtherSingleTextField from 'app/components/dfields/OtherSingleTextField';
import {
  FIELD_TYPE,
  type IDynamicField,
  type IDynamicFieldValue,
  type IDynamicFieldValues,
  type IFullCase,
  SavingStatus,
  TabsEnum
} from 'app/mobxStore/types';
import type { Control } from 'react-hook-form/dist/types/form';
import { getTagName, isOtherSelected } from './helper';
import { useRootStore } from '../../mobxStore';
import { useDebounce } from '../../../@fuse/hooks';
import { observer } from 'mobx-react-lite';
import useNotifyCaseChanges from '../caseViews/hooks/useNotifyCaseChanges';
import { DynamicField } from '../../../utils/form/shared';
import { reverseParseToTextArray } from '../../../utils/form/submit';
import _ from 'lodash';
import AmplService from '../../services/amplService';
import { lite } from '../../services/apiService/apiService';
import FieldLastUpdatedRow from 'app/components/fieldLastUpdatedRow/FieldLastUpdatedRow';

interface IProps {
  field: IDynamicField;
  control: Control<any>;
  allValues: IDynamicFieldValues;
  setFocus: (id: string) => void;
  isDirty: boolean;
  isOtherDirty: boolean;
  onValueChange: (
    fieldId: string,
    values: IDynamicFieldValue,
    otherValues: IDynamicFieldValue | null,
    type: string,
    multi: boolean,
    oldValue: IDynamicFieldValue,
    oldOtherValue: IDynamicFieldValue | null
  ) => void;
  submitChange: boolean;
}

const DEBOUNCE_NON_TEXT_MSEC = 1000;
const DEBOUNCE_TEXT_MSEC = 3000;
const Field = (props: IProps): React.JSX.Element => {
  const { caseStore } = useRootStore();
  const openedCase = caseStore.openedCase as IFullCase;
  const { isResetting } = openedCase.basicCase;
  const { setFocus, allValues, field, control, onValueChange, submitChange } = props;
  const { handleNotifyOnChanges } = useNotifyCaseChanges();
  const TagName = getTagName(field);
  const fieldValues = allValues[field.id];
  const displayOther = isOtherSelected(field, fieldValues) && field.editMode;
  const [isInit, setIsInit] = useState(false);
  const [needAutoFocus, setNeedAutoFocus] = useState(false);
  const value = allValues[field.id];
  const [submittedTime, setSubmittedTime] = useState<number>(0);
  const debounceFunc = useCallback(
    async (allValues: IDynamicFieldValues) => {
      setSubmittedTime(new Date().getTime());
      await handleNotifyOnChanges(
        openedCase.basicCase.id,
        openedCase.basicCase.data.state,
        openedCase.referencedData.caseFieldValues
      );
      openedCase.basicCase.store.setIsSaving(true);
      const updatedValues = await field.update(allValues);
      openedCase.basicCase.updateCaseEditLock(true);
      openedCase.basicCase.store.setIsSaving(false);
      openedCase.basicCase.store.setSelectedTab(TabsEnum.CASE);
      if (field.id === DynamicField.INTRAOP_IMAGING) {
        const values = reverseParseToTextArray(updatedValues.values);
        const otherValues = reverseParseToTextArray(updatedValues.otherValues);

        // Filter out the value "Other" from the values array
        const filteredValues = values.filter((v: string) => v !== 'Other');
        const allValues = [...filteredValues, ...otherValues];
        const intraopImagingValue = allValues.join(', ');
        await openedCase.basicCase.updateCaseMetaDataToServer({
          intraop_text: intraopImagingValue
        });
        openedCase.basicCase.setIntraopText(intraopImagingValue);
      }
    },
    [field.editMode]
  );
  const debounceTime = field.type === FIELD_TYPE.TEXT ? DEBOUNCE_TEXT_MSEC : DEBOUNCE_NON_TEXT_MSEC;
  const debounceUpdate = useDebounce(debounceFunc, debounceTime, {
    leading: false,
    trailing: true
  });
  useEffect(() => {
    debounceUpdate.refreshFunc();
  }, [debounceFunc]);

  const otherValue = field.hasOther ? allValues[`${field.id}-other`] : null;
  const [curValue, setCurValue] = useState<IDynamicFieldValue>(value);
  const [curOtherValue, setCurOtherValue] = useState<IDynamicFieldValue | null>(otherValue);

  const oldValueRef = useRef(value);
  const oldOtherValueRef = useRef(otherValue);

  const valToStr = (value: IDynamicFieldValue | null): IDynamicFieldValue | null => {
    if (!value || lite) {
      return value;
    }

    // obfuscate because may contain sensitive data
    return value.map(() => '***');
  };

  const debounceOnValueChange = useDebounce(
    async (v: IDynamicFieldValue, ov: IDynamicFieldValue | null) => {
      AmplService.sendCaseEvent(AmplService.EVENTS.CASE_FIELD_CHANGED, openedCase.basicCase, {
        [AmplService.ATTRIBUTES.FIELD_ID]: field.id,
        [AmplService.ATTRIBUTES.FIELD_OLD_VALUE]: oldValueRef.current,
        [AmplService.ATTRIBUTES.FIELD_OLD_YOUR_OWN_VALUE]: valToStr(oldOtherValueRef.current),
        [AmplService.ATTRIBUTES.FIELD_NEW_VALUE]: v,
        [AmplService.ATTRIBUTES.FIELD_NEW_YOUR_OWN_VALUE]: valToStr(ov)
      });

      onValueChange(
        field.id,
        v,
        ov,
        field.type,
        field.multiple,
        oldValueRef.current,
        oldOtherValueRef.current
      );
      oldValueRef.current = v;
      oldOtherValueRef.current = ov;
    },
    debounceTime,
    {
      leading: false,
      trailing: true
    }
  ).debounceFunc;

  useEffect(() => {
    if (!submitChange) {
      return;
    }
    if (_.isEqual(curValue, value) && _.isEqual(curOtherValue, otherValue)) {
      return;
    }

    setCurValue(value);
    setCurOtherValue(otherValue);
    openedCase.basicCase.store.setSavingStatus(SavingStatus.SAVING);
    void debounceUpdate.debounceFunc(allValues);
    debounceOnValueChange(value, otherValue);
  }, [value, otherValue]);

  useEffect(() => {
    if (!isInit) {
      setIsInit(true);
      return;
    }
    if (isResetting) {
      openedCase.basicCase.setIsResetting(false);
      return;
    }

    if (displayOther) {
      setNeedAutoFocus(true);
      setTimeout(() => {
        setNeedAutoFocus(false);
      }, 1000);
    }
  }, [allValues[field.id]]);
  const getOtherTextField = (): React.JSX.Element => {
    if (field.multiple) {
      return <></>;
    }
    return (
      <OtherSingleTextField
        setFocus={setFocus}
        needAutoFocus={needAutoFocus}
        field={field}
        control={control}
      />
    );
  };

  const getOther = getOtherTextField();

  const isEmpty = (): boolean => {
    return field.value.length === 0 || (field.value.length === 1 && field.value[0] === '');
  };

  if (!field.editMode && isEmpty()) {
    return <></>;
  }

  return (
    <div key={field.id} className="field">
      <TagName field={field} key={field.id} control={control} allValues={allValues} />
      {displayOther && getOther}
      <FieldLastUpdatedRow submittedTime={submittedTime} field={field} />
    </div>
  );
};

export default observer(Field);
