import React from 'react';
import NewCaseFab from 'app/components/orBoard/newCaseFab/NewCaseFab';
import DailyHuddleStats from '../casesList/DailyHuddleStats';

import './EmptyList.scss';
import { ReactComponent as PenguinSkating } from '../../../assets/penguins/penguin_skating_animated.svg';
import clsx from 'clsx';
import Button from 'app/components/buttons/Button';
import { observer } from 'mobx-react-lite';
import useEmptyList from './useEmptyList';
import useEmptyListLiteNonSurgeon from './useEmptyListLiteNonSurgeon';
import { useRootStore } from '../../../mobxStore';

const EmptyList = (): React.JSX.Element => {
  const {
    text: textDefault,
    onClick: onClickDefault,
    buttonText: buttonTextDefault
  } = useEmptyList();
  const {
    text: textNhns,
    onClick: onClickNhns,
    buttonText: buttonTextNhns
  } = useEmptyListLiteNonSurgeon();
  const { liteNonSurgeon, liteSurgeon } = useRootStore();

  const text = liteNonSurgeon ? textNhns : textDefault;
  const onClick = liteNonSurgeon ? onClickNhns : onClickDefault;
  const buttonText = liteNonSurgeon ? buttonTextNhns : buttonTextDefault;

  return (
    <div
      className={clsx('empty-list', {
        'no-toggles': liteSurgeon,
        'no-toggles no-pickers': liteNonSurgeon
      })}
    >
      <DailyHuddleStats />
      <div className="empty-container">
        <div className="image-container">
          <PenguinSkating />
        </div>
        <div className="text">{text}</div>
        {onClick && (
          <Button classNames="primary nav-button" buttonText={buttonText} onClick={onClick} />
        )}
      </div>
      <NewCaseFab />
    </div>
  );
};

export default observer(EmptyList);
