import OneSignal from 'react-onesignal';
import apiService from 'app/services/apiService/apiService';
import ONE_SIGNAL_CONFIG from './oneSignalServiceConfig';
import ffService from '../ffService/ffService';
import { toast } from 'react-toastify';
import ErrorMonitor from '../errorMonitor/errorMonitor';
import * as Sentry from '@sentry/react';
import AmplService from '../amplService/amplService';

const getExtId = async (): Promise<string> => {
  const id = await new Promise((resolve, reject) => {
    window.OneSignalDeferred?.push((OneSignal: any) => {
      resolve(OneSignal?.User?.externalId);
    });
  });
  return id as string;
};

class OneSignalService {
  client = OneSignal;

  isInit = false;
  loginHandled = false;
  count = 0;
  getExtId = getExtId;
  onLoginCallback = (): void => {};

  enabled(): boolean {
    return ffService.isFlagOnPushNotifications();
  }

  async setBadgeCount(count: number): Promise<void> {
    if (!this.enabled()) {
      console.log('OneSignal is not enabled. Not calling setBadgeCount.');
      return;
    }
    if (!('setAppBadge' in navigator)) {
      console.log('setAppBadge is not supported');
      return;
    }

    try {
      // @ts-expect-error there is no type for setAppBadge
      await navigator.setAppBadge(count);
    } catch (error) {
      console.error('Failed to set app badge:', error);
    }
  }

  async init(): Promise<void> {
    if (this.isInit) {
      return;
    }
    if (!this.enabled()) {
      console.log('OneSignal is not enabled. Not calling init.');
      return;
    }
    if (!ONE_SIGNAL_CONFIG.appId) {
      console.log('No app id for OneSignal');
      return;
    }
    await this.client.init({
      appId: ONE_SIGNAL_CONFIG.appId
    });
    console.log('oneSignalService initialized');
    this.isInit = true;
  }

  showSlidedownPrompt(force = false): void {
    if (!this.enabled()) {
      return;
    }

    this.client.Slidedown.addEventListener('slidedownShown', () => {
      AmplService.sendEvent(AmplService.EVENTS.PUSH_NOTIFICATIONS_PROMPT_SHOWN);
    });

    void this.client.Slidedown.promptPush({
      force
    });
  }

  registerToSubscriptionChangedEvent(callback: (isSubscribed: boolean) => Promise<void>): void {
    if (!this.enabled()) {
      return;
    }
    this.client.User.PushSubscription.addEventListener('change', event => {
      void callback(event.current.optedIn);
    });
  }

  registerToForegroundWillDisplayEvent(): void {
    if (!this.enabled()) {
      return;
    }
    this.client.Notifications.addEventListener('foregroundWillDisplay', async event => {
      console.log('foregroundWillDisplay', event);
      await this.setBadgeCount(this.count);
      this.count = this.count + 1;
    });
  }

  async isOptedIn(): Promise<boolean> {
    if (!this.enabled()) {
      console.log('isOptedIn: OneSignal is not enabled. Returning false.');
      return false;
    }
    try {
      await this.init();
      if (!this.isInit) {
        ErrorMonitor.captureException(new Error('OneSignal is not initialized'));
        return false;
      }
    } catch (error) {
      ErrorMonitor.captureException(error as Error);
      return false;
    }

    const ret = this.client?.User?.PushSubscription?.optedIn;
    console.log('isOptedIn:', ret);
    return this.client?.User?.PushSubscription?.optedIn ?? false;
  }

  async checkExtId(): Promise<void> {
    const curExtId = await this.getExtId();
    if (curExtId) {
      return;
    }
    Sentry.captureException(new Error('No ext id after api call'));
  }

  async handleLogin(currentUserId: string, email: string): Promise<void> {
    if (this.loginHandled) {
      return;
    }
    this.loginHandled = true;
    await this.handleExtId(currentUserId, email);
    this.onLoginCallback();

    setTimeout(async () => {
      await this.handleExtId(currentUserId, email, true);
    }, 5000);
  }

  async handleExtId(currentUserId: string, email: string, delayed: boolean = false): Promise<void> {
    const curExtId = await this.getExtId();
    if (curExtId) {
      console.log('already has extId:');
      AmplService.sendEvent(AmplService.EVENTS.HANDLE_EXT_ID, {
        [AmplService.ATTRIBUTES.EXT_ID_SET]: true,
        [AmplService.ATTRIBUTES.DELAYED]: delayed
      });
      return;
    }
    // delayed + not have ext id issue
    if (delayed) {
      Sentry.captureException(new Error('No ext id after 5 seconds'));
    }
    console.log('do not have ext id');
    AmplService.sendEvent(AmplService.EVENTS.HANDLE_EXT_ID, {
      [AmplService.ATTRIBUTES.EXT_ID_SET]: false,
      [AmplService.ATTRIBUTES.DELAYED]: delayed
    });
    const hash = await apiService.getIdHash();
    if (!hash) {
      ErrorMonitor.captureMessage('No hash for user');
      return;
    }
    AmplService.sendEvent(AmplService.EVENTS.HANDLE_EXT_ID_BEFORE_LOGIN);
    await this.client.login(currentUserId, hash);
    this.client.User.addAlias('email', email);
    AmplService.sendEvent(AmplService.EVENTS.HANDLE_EXT_ID_AFTER_LOGIN);
    await this.checkExtId();
  }

  async showOneSignalPopup(currentUserId: string, email: string, force: boolean): Promise<boolean> {
    if (!this.enabled()) {
      return false;
    }
    try {
      await this.init();
      if (!this.isInit) {
        ErrorMonitor.captureException(new Error('OneSignal is not initialized'));
        return false;
      }
      this.showSlidedownPrompt(force);
      this.registerToSubscriptionChangedEvent(
        this.getSubscriptionChangedEventCallback(currentUserId, email)
      );
    } catch (error) {
      ErrorMonitor.captureException(error as Error);
    }

    return true;
  }

  private getSubscriptionChangedEventCallback(currentUserId: string, email: string) {
    return async (isSubscribed: boolean) => {
      try {
        console.log('subscription changed isSubscribed:', isSubscribed);
        const isOptedInInApi = await this.isOptedIn();
        if (!isSubscribed && !isOptedInInApi) {
          AmplService.sendEvent(AmplService.EVENTS.PUSH_NOTIFICATIONS_PROMPT_NO_RESPONSE);
          await this.delayCheckOptedInStatus(currentUserId, email);
          return;
        }
        if (isSubscribed !== isOptedInInApi) {
          Sentry.captureException(new Error('oneSignal event data error'), {
            extra: {
              isSubscribed,
              isOptedInInApi
            }
          });
        }
        AmplService.sendEvent(AmplService.EVENTS.PUSH_NOTIFICATIONS_PROMPT_BUTTON, {
          [AmplService.ATTRIBUTES.PUSH_PROMPT_BUTTON]: true,
          dbg_isSubscribed: isSubscribed,
          dbg_isOptedInInApi: isOptedInInApi,
          dbg_delayed: false
        });
        await this.handleLogin(currentUserId, email);

        // show this toast only once by settings it's toast id
        toast.success('Done. Notifications are all set!', {
          toastId: 'push-notifications-toast'
        });
      } catch (error) {
        ErrorMonitor.captureException(new Error(`subscription changed error: ${String(error)}`));
      }
    };
  }

  async delayCheckOptedInStatus(currentUserId: string, email: string): Promise<void> {
    setTimeout(async () => {
      const isOptedInInApi = await this.isOptedIn();
      AmplService.sendEvent(AmplService.EVENTS.PUSH_NOTIFICATIONS_PROMPT_BUTTON, {
        [AmplService.ATTRIBUTES.PUSH_PROMPT_BUTTON]: isOptedInInApi,
        dbg_isSubscribed: false,
        dbg_isOptedInInApi: isOptedInInApi,
        dbg_delayed: true
      });
      if (!isOptedInInApi) {
        return;
      }
      Sentry.captureException(new Error('oneSignal event data timer error'), {
        extra: {
          isSubscribed: false,
          isOptedInInApi
        }
      });
      await this.handleLogin(currentUserId, email);
      toast.success('Done. Notifications are all set!', {
        toastId: 'push-notifications-toast'
      });
    }, 5000);
  }

  setOnLoginCallback(cb: () => void): void {
    this.onLoginCallback = cb;
  }
}

const instance = new OneSignalService();
export default instance;
