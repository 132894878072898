import { useTranslation } from 'react-i18next';
import oneSignalService from 'app/services/oneSignalService';
import AmplService from 'app/services/amplService/amplService';

import './EmptyList.scss';
import { useRootStore } from '../../../mobxStore';
import { isDateInPast } from '../../../mobxStore/caseStoreHelper';
import useOurSearchParams from '../../caseViews/hooks/useOurSearchParams';
import { useEffect, useState } from 'react';

const useEmptyList = (): {
  text: string;
  onClick: (() => Promise<void>) | undefined;
  buttonText?: string;
} => {
  const { t } = useTranslation();
  const { caseStore, userStore, isPushOptIn, pushSupported } = useRootStore();
  const { setOurSearchParams } = useOurSearchParams();
  const currentUserId = userStore.loggedInUser.data.id;
  const [shouldOptIn, setShouldOptedIn] = useState<boolean>(false);

  useEffect(() => {
    setShouldOptedIn(pushSupported && !isPushOptIn);
  }, [pushSupported, isPushOptIn]);

  const getNextCaseText = (): { date: Date | undefined; text: string } => {
    const selectedDate = caseStore.selectedDateFilter;

    if (isDateInPast(selectedDate, caseStore.rootStore.tz)) {
      return { date: undefined, text: t('emptyStateNoHuddlesHere') };
    }

    const weekday = selectedDate.getDay();
    const daysToSunday = 7 - weekday;
    const daysToEndOfNextWeek = 7 + daysToSunday;
    for (let i = 1; i <= 30; i++) {
      const date = new Date(selectedDate);
      date.setDate(date.getDate() + i);
      if (!caseStore.hasCaseInDateCurrentUser(date)) {
        continue;
      }

      if (i === 1) {
        return { date, text: t('emptyCaseGotHuddlesTomorrow') };
      }
      if (i <= daysToSunday) {
        const options: Intl.DateTimeFormatOptions = { weekday: 'long' };
        const dayStr = date.toLocaleDateString('en-US', options);
        const text = t('emptyCaseGotHuddlesOn', { to: dayStr });
        return { date, text };
      }
      if (i <= daysToEndOfNextWeek) {
        const options: Intl.DateTimeFormatOptions = { weekday: 'long' };
        const dayStr = date.toLocaleDateString('en-US', options);
        const text = t('emptyCaseGotHuddlesNextWeek', { to: dayStr });
        return { date, text };
      }
      const dateStr = date.toLocaleDateString('locale');
      return { date, text: t('emptyCaseGotHuddlesOn', { to: dateStr }) };
    }

    return { date: undefined, text: t('emptyStateNoHuddlesSoon') };
  };

  const enablePushButton = {
    onClick: async (): Promise<void> => {
      void oneSignalService.showOneSignalPopup(
        currentUserId,
        userStore.loggedInUser.data.email,
        true
      );
      AmplService.sendEvent(AmplService.EVENTS.HOME_CASE_LIST_EMPTY_ENABLE_NOTIFICATIONS);
    },
    text: 'enablePush'
  };

  if (shouldOptIn) {
    return {
      text: t('emptyStateEnableNotifications'),
      onClick: enablePushButton.onClick,
      buttonText: enablePushButton.text
    };
  }

  const { date: nextCase, text } = getNextCaseText();

  const handleSetDateFilter = async (): Promise<void> => {
    if (nextCase === undefined) {
      return;
    }

    setOurSearchParams({ date: nextCase }, false);
  };

  return {
    text,
    onClick: nextCase ? handleSetDateFilter : undefined,
    buttonText: 'emptyStateTakeMeThere'
  };
};

export default useEmptyList;
