import UserStore from './UserStore';
import type {
  ICareTeamDefaultsStore,
  ICaseStore,
  IFullUserData,
  IMetaDataStore,
  IRootStore,
  IUserStore
} from './types';
import CaseStore from './CaseStore';
import MetaDataStore from './MetaDataStore';
import { makeAutoObservable } from 'mobx';
import { USER_CASE_ROLES } from '../consts';
import { canUpdate, getUserRoleInCase, needsBriefApproval } from 'utils/form/shared';
import CareTeamDefaultsStore from './CareTeamDefaultsStore';
import { lite } from '../services/apiService/apiService';
import { isInstalled, isStandAloneSupported } from './caseStoreHelper';
import ffService from '../services/ffService/ffService';

const EXPIRE_LOCK_TIME = 1000 * 60 * 5;

class RootStore implements IRootStore {
  userStore: IUserStore;
  caseStore: ICaseStore;
  metaDataStore: IMetaDataStore;
  careTeamDefaultsStore: ICareTeamDefaultsStore;

  isPushOptIn = false;
  curEditMode = false;

  constructor() {
    this.userStore = new UserStore(this);
    this.caseStore = new CaseStore(this);
    this.metaDataStore = new MetaDataStore(this);
    this.careTeamDefaultsStore = new CareTeamDefaultsStore(this);
    makeAutoObservable(this, {
      userStore: false,
      caseStore: false,
      metaDataStore: false
    });
  }

  get showCasesToggle(): boolean {
    return !this.userStore.loggedInUser.isVendor && !lite;
  }

  get isLoggedInUserAttending(): boolean {
    return this.userStore.loggedInUser.isAttending;
  }

  isLoggedInUserId(id: string): boolean {
    return this.userStore.loggedInUser.data.id === id;
  }

  get userRoleInCase(): USER_CASE_ROLES {
    const openedCase = this.caseStore.openedCase;
    if (openedCase === null) {
      return USER_CASE_ROLES.NONE;
    }
    const userData = this.userStore.loggedInUser.data;
    return getUserRoleInCase(openedCase.basicCase.data, userData);
  }

  findUserById(id: string): IFullUserData | undefined {
    return this.metaDataStore.users.find(user => user.id === id);
  }

  get needsBriefApproval(): boolean {
    if (this.caseStore.openedCase === null) {
      return false;
    }
    const caseData = this.caseStore.openedCase.basicCase.data;

    return needsBriefApproval(
      caseData.isReady,
      this.caseStore.openedCase.basicCase.isInPast(),
      this.userRoleInCase
    );
  }

  get canUpdate(): boolean {
    if (this.caseStore.openedCase === null) {
      return false;
    }
    return canUpdate(this.caseStore.openedCase.basicCase.data, this.userRoleInCase);
  }

  get showEditMode(): boolean {
    if (this.caseStore.openedCase === null) {
      return false;
    }
    const basicCase = this.caseStore.openedCase.basicCase;
    return this.canUpdate && !basicCase.data.isReady && !this.hasLock;
  }

  get hasLock(): boolean {
    if (this.caseStore.openedCase === null) {
      return false;
    }
    const basicCase = this.caseStore.openedCase.basicCase;
    if (!basicCase.data.editedAt || !basicCase.data.editedBy) {
      return false;
    }
    if (this.userStore.loggedInUser.data.id === basicCase.data.editedBy) {
      return false;
    }
    const editedAt = new Date(basicCase.data.editedAt);
    const now = new Date();
    return now.valueOf() - editedAt.valueOf() < EXPIRE_LOCK_TIME;
  }

  get liteNonSurgeon(): boolean {
    return lite && !this.userStore.loggedInUser.isAttending;
  }

  get liteSurgeon(): boolean {
    return lite && this.userStore.loggedInUser.isAttending;
  }

  get tz(): string {
    return this.metaDataStore.tz;
  }

  setCurEditMode(editMode: boolean): void {
    this.curEditMode = editMode;
  }

  setIsPushOptIn(isOptIn: boolean): void {
    this.isPushOptIn = isOptIn;
  }

  get pushSupported(): boolean {
    return isStandAloneSupported() && isInstalled() && ffService.isFlagOnPushNotifications();
  }
}

export default RootStore;
